<script setup lang="ts">
const { routeName } = useRouteName();
const modal = useModal();
const router = useRouter();

const upgrade = () => {
  modal.close();
  navigateTo('/account/billing');
};

const cancel = () => {
  modal.close();

  if (routeName.value === 'dataSources') {
    router.back();
  }
};

const handleClosePrevented = () => {
  cancel();
};
</script>
<template>
  <UModal prevent-close @close-prevented="handleClosePrevented">
    <UCard
      :ui="{
        ring: '',
        divide: '',
      }"
    >
      <template #header>
        <div class="flex flex-col items-center">
          <UIcon
            name="i-ph-crown"
            size="xl"
            class="text-4xl text-primary-500"
          />
          <p class="main-title">Data Sources is for Teams</p>
        </div>
      </template>
      <div class="space-y-4 flex flex-col items-center">
        <p>
          Data Sources is a team-only feature. Upgrade to a Team account to use
          your private data in your workflows.
        </p>
      </div>

      <template #footer>
        <div class="flex flex-col items-center mb-4">
          <div class="space-x-2">
            <UButton size="xl" color="white" @click="cancel"> Cancel</UButton>
            <UButton size="xl" @click="upgrade"> Upgrade to Team</UButton>
          </div>
        </div>
      </template>
    </UCard>
  </UModal>
</template>
