<script setup lang="ts">
import CarbonIntegrationButton from '~/components/dataSources/CarbonIntegrationButton.vue';
import { useWorkspaceStore } from '~/stores/workspaces';

const modal = useModal();

const workspaceStore = useWorkspaceStore();
const { workspace } = storeToRefs(workspaceStore);

const dataSourcesStore = useDataSourcesStore();
const { currentFolder } = storeToRefs(dataSourcesStore);

const { refresh, localOnly } = defineProps({
  refresh: {
    type: Function,
    required: true,
  },
  localOnly: {
    type: Boolean,
    required: true,
  },
});

const uploadTarget = computed(() =>
  currentFolder.value ? currentFolder.value.name : 'Workspace Data Sources',
);
</script>
<template>
  <UModal>
    <UCard
      class="w-full"
      :ui="{
        ring: '',
        divide: '',
      }"
    >
      <template #header>
        <div class="main-title">Upload to {{ uploadTarget }}</div>
      </template>

      <div class="space-y-4">
        <AppFileInput
          owner-type="Workspace"
          :owner-id="workspace.id"
          :folder-id="currentFolder ? currentFolder.id : null"
          :allow-multiple="true"
          :editable="true"
          :on-success="refresh"
        />
        <div v-if="!localOnly" class="space-y-4">
          <hr />
          <CarbonIntegrationButton service="GOOGLE_DRIVE" />
          <CarbonIntegrationButton service="NOTION" />
        </div>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-2">
          <UButton type="button" size="xl" color="white" @click="modal.close"
            >Cancel
          </UButton>
          <UButton type="submit" size="xl" @click="modal.close">Done</UButton>
        </div>
      </template>
    </UCard>
  </UModal>
</template>
